import request from '@/utils/request'

const fapiao_applicationApi = {
  fapiao_application_list: '/fapiao_application/',
  fapiao_application_create: '/fapiao_application/',
  fapiao_application_partial_update: '/fapiao_application/',
  fapiao_bill_list: '/bill/'
}

/**
 * 列表
 */
export function fapiao_application_list (parameter) {
  return request({
    url: fapiao_applicationApi.fapiao_application_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 列表
 */
export function fapiao_bill_list (parameter) {
  return request({
    url: fapiao_applicationApi.fapiao_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function fapiao_application_create (parameter) {
  return request({
    url: fapiao_applicationApi.fapiao_application_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function fapiao_application_partial_update (parameter, fapiao_application_id) {
  return request({
    url: fapiao_applicationApi.fapiao_application_partial_update + fapiao_application_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
