<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" allow-clear placeholder="请输入名称"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="账号">
              <a-input v-model="queryParam.account" allow-clear placeholder="请输入账号"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker
                @change="time"
                format="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="是否匹配">
              <a-switch v-model="queryParam.is_match"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right;">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            v-if="!record.is_match"
            @confirm="deleteRecord(record)"
          >
            <a v-action:delete>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  fapiao_application_create,
  fapiao_application_partial_update
} from '@/api/fapiao_application'
import { Dictionaries } from '@/common/AllConstant'
import { corporation_list } from '@/api/corporation'
import { property_bank_flow_delete, property_bank_flow_list } from '@/api/property_bank_flow'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '账号',
          width: 200,
          dataIndex: 'account'
        },
        {
          title: '金额',
          align: 'right',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'amount'
        },
        {
          title: '生成时间',
          dataIndex: 'create_time',
          align: 'center',
          ellipsis: true
        },
        {
          title: '付款时间',
          dataIndex: 'pay_time',
          ellipsis: true
        },
        {
          title: '匹配',
          align: 'center',
          dataIndex: 'is_match',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '所有人',
          align: 'center',
          dataIndex: 'property_owner',
          customRender: (text) => this.$Dictionaries.account_owner[text] || '无'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return property_bank_flow_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings[0] === '') {
        this.queryParam.start_time = undefined
        this.queryParam.end_time = undefined
        return
      }
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
        if (!(this.dataSource.length > 0)) {
          this.queryParam.corporation_id = ''
        }
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id

      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    agree (record) {
      fapiao_application_partial_update({ target: 2 }, record.id).then(({ data }) => {
        console.log(data)
        this.$refs.table.refresh()
      })
    },
    reject: function (record) {
      fapiao_application_partial_update({ target: 3 }, record.id).then(({ data }) => {
        console.log(data)
        this.$refs.table.refresh()
      })
    },
    detail (record) {
      console.log(record)
      this.$emit('onRead', record)
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    deleteRecord (record) {
      property_bank_flow_delete(record).then(res => {
        if (res && res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          fapiao_application_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            this.$message.info('新增成功')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
