import request from '@/utils/request'

const property_bank_flow_Api = {
  property_bank_flow_list: '/property/bank_flow/',
  property_bank_flow_delete: '/property/bank_flow/',
  fund_flow_list: '/fund_flow/',
  fund_flow_create: '/fund_flow/',
  fund_flow_export: '/fund_flow/export/'
}

/**
 * 列表
 */
export function property_bank_flow_list (parameter) {
  return request({
    url: property_bank_flow_Api.property_bank_flow_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 删除
 */
export function property_bank_flow_delete (parameter) {
  return request({
    url: property_bank_flow_Api.property_bank_flow_delete + parameter.id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function fund_flow_list (parameter) {
  return request({
    url: property_bank_flow_Api.fund_flow_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 新建
 */
export function fund_flow_create (parameter) {
  return request({
    url: property_bank_flow_Api.fund_flow_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 导出
 */
export function fund_flow_export (parameter) {
  return request({
    url: property_bank_flow_Api.fund_flow_export,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
